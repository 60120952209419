import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { PRIMARY } from "../../../vars/vars";
import { Spinner } from "react-bootstrap";
import placeholderWebp from "../../../assets/placeholders/pedders_placeholder.webp";
import placeholder from "../../../assets/placeholders/pedders_placeholder.jpg";
import KitComponent from "../KitComponent/KitComponent";
import { useAppContext } from "../../../context/AppContext";
import plus from "./../../../assets/qty-plus.svg";
import minus from "./../../../assets/qty-minus.svg";
import DetailsInputButtonInBasket from "../../atoms/DetailsInputButtonInBasket/DetailsInputButtonInBasket";
import { getItemData } from "../../../axios/api";
import styles from "./BasketKit.module.scss";
import DeliveryInfoDetails from "../../atoms/DeliveryInfoDetails/DeliveryInfoDetails";

const isDateDifferenceGreaterThan = (deliveryObj, days) => {
  const currentDate = new Date();
  const deliveryDate = new Date(deliveryObj.eta);

  const differenceInMilliseconds = deliveryDate - currentDate;

  const differenceInDays = Math.ceil(
    differenceInMilliseconds / (24 * 60 * 60 * 1000)
  );

  return differenceInDays <= days;
};

const findDefaultStockLocation = (available) => {
  return available.find((e) => e.default).location;
};

const BasketKit = ({
  item,
  currency,
  changeHanlder,
  deleteHandler,
  isLoading,
}) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const [showComponents, setShowComponents] = useState(false);
  const [components, setComponents] = useState([]);
  const { basketId, basket, userCountry } = useAppContext();
  const [max, setMax] = useState(1);
  const [availability, setAvailability] = useState(null);
  const [part, setPart] = useState(null);

  const onChangeHandler = async () => {
    changeHanlder(quantity, item._id, basketId);
  };

  useEffect(() => {
    onChangeHandler();
  }, [quantity]);

  useEffect(() => {
    async function fetch(item, userCountry) {
      const [{ data: part }] = await getItemData(
        item.item.urlcomponent,
        userCountry.code
      );

      setPart(part);

      const transit = {
        productsInTransitInLessTreeWeeks: 0,
        deliveryDateUnderThreeWeeks: "",
        productsInTransitInOverTreeWeeks: 0,
        deliveryDateOverThreeWeeks: "",
        numberOfProductsInTransit: 0,
      };

      const defaultStockLocation = findDefaultStockLocation(part.available);

      const location = part.available.find(
        ({ location }) => location === defaultStockLocation
      );

      const deliveries = part.deliveries[defaultStockLocation];

      if (deliveries && Object.keys(deliveries).length > 0) {
        deliveries.forEach((obj) => {
          const deliveryTimeCheck = isDateDifferenceGreaterThan(obj, 20);
          transit.numberOfProductsInTransit += obj.quantity;

          if (deliveryTimeCheck) {
            transit.productsInTransitInLessTreeWeeks += obj.quantity;
            transit.deliveryDateUnderThreeWeeks = obj.eta;
          } else {
            transit.productsInTransitInOverTreeWeeks += obj.quantity;
            transit.deliveryDateOverThreeWeeks = obj.eta;
          }
        });
      }

      const locationQty = location.quantityavailable || 0;
      const manufacturer = part.available.find(
        ({ location }) => location === "manufacturer"
      );
      let manufacturerQty =
        (manufacturer &&
          location.specialbackorder &&
          manufacturer.quantityavailable) ||
        0;

      const max =
        locationQty +
        manufacturerQty +
        (location.intransit || transit.numberOfProductsInTransit || 0);
      if (location.stockcategory === "stockclearance") {
        setMax(location.quantityavailable || 0);
      } else {
        setMax(max);
      }
      setAvailability({ locationQty, manufacturerQty, transit });
    }
    fetch(item, userCountry);
  }, []);

  useEffect(() => {
    const kitComponents = basket.transactionlines.filter(
      (obj) => obj.init === item._id
    );
    setComponents(kitComponents);
  }, [basket]);

  const handleClick = (type) => {
    switch (type) {
      case "plus":
        if (quantity + 1 > max) {
          break;
        }
        setQuantity(quantity + 1);
        break;
      case "minus":
        if (quantity - 1 === 0) {
          break;
        }
        setQuantity(quantity - 1);
        break;
      default:
        break;
    }
  };
  return (
    <>
      <DescriptionWrapper>
        <Photo to={`/details/${item.item.urlcomponent}`}>
          {item.item.photos.length ? (
            <img src={item.item.photos[0].url} alt={item.displayname} />
          ) : (
            <picture>
              <source srcSet={placeholderWebp} type="image/webp" />
              <img src={placeholder} alt={item.displayname} />
            </picture>
          )}
        </Photo>
        <div>
          <Description to={`/details/${item.item.urlcomponent}`}>
            {item.item.description}
            <br />
            {item.displayname}
          </Description>
          {availability && (
            <DeliveryInfoDetails
              availability={availability}
              part={part}
              quantity={quantity}
            />
          )}
        </div>
      </DescriptionWrapper>

      <Price>
        <span>Cena:</span>
        {item.grossprice || item.price} {currency}
      </Price>

      <InputWrapper>
        <span>Ilość:</span>

        <StyledInputArea>
          <DetailsInputButtonInBasket
            image={minus}
            name={"minus"}
            clickHandler={handleClick}
          />
          <StyledInput
            className={styles.Input}
            disabled
            type={"number"}
            value={quantity}
            min={"1"}
            max={max}
          />
          <DetailsInputButtonInBasket
            image={plus}
            name={"plus"}
            clickHandler={handleClick}
          />
        </StyledInputArea>
      </InputWrapper>

      <Price>
        <span>Suma częściowa:</span>
        {item.amount || item.grosamount} {currency}
      </Price>

      <Buttons
        style={{
          borderBottom: showComponents ? "none" : "1px solid #dee2e6",
        }}
      >
        <Button disabled={isLoading} onClick={() => deleteHandler(item._id)}>
          {isLoading ? (
            <Spinner
              animation="border"
              color={"white"}
              size={"sm"}
              role="status"
            />
          ) : (
            "Usuń"
          )}
        </Button>
        <Button onClick={() => setShowComponents(!showComponents)}>
          {" "}
          {showComponents ? "Ukryj komponenty" : "Pokaż komponenty"}
        </Button>
      </Buttons>

      {showComponents && (
        <ComponentsContainer>
          {components.map((item) => (
            <KitComponent key={item._id} item={item} />
          ))}
        </ComponentsContainer>
      )}
    </>
  );
};

export default BasketKit;

const ComponentsContainer = styled.div`
  grid-column: 1 / 5;
  padding: 0 20px 20px;
  display: grid;
  flex-direction: column;
  border-bottom: 1px solid #dee2e6;

  @media (min-width: 768px) {
    padding: 0 40px 20px;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    column-gap: 20px;
  }
`;

const Button = styled.button`
  border: 1px solid #cdcdcd;
  background-color: white;
  color: #333;
  min-width: 100px;
  font-size: 12px;
  padding: 7px;

  &:hover {
    color: white;
    background-color: ${PRIMARY};
  }
`;

const Buttons = styled.div`
  grid-column: 1 / 5;
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #dee2e6;
  border-bottom: 1px solid #dee2e6;
  padding: 8px 8px 20px 8px;

  @media (min-width: 767px) {
    padding: 8px 8px 20px 8px;
    margin-top: 0px;
  }
`;

const InputWrapper = styled.div`
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  padding: 10px 8px 0;
  text-align: right;
  align-items: flex-end;

  span {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    color: #666666;
    font-size: 16px;
  }

  @media (min-width: 767px) {
    grid-column: auto;
    padding: 10px 0 0;
    display: block;
    margin-left: auto;
    span {
      display: none;
    }
  }
`;

const Input = styled.input`
  width: 30px;
  height: 24px;
  text-align: center;
  padding: 0px;

  &[type="number"] {
    -moz-appearance: textfield;
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  @media (min-width: 767px) {
    width: 47px;
    padding: 2px 0;
  }
`;

const Price = styled.div`
  grid-column: 1 / 5;
  display: flex;
  justify-content: space-between;
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: #666666;
  font-size: 16px;
  vertical-align: top;
  padding: 10px 8px 0;
  text-align: right;

  @media (min-width: 767px) {
    padding: 10px 0px 0;
    grid-column: auto;
    display: block;
    font-size: 17px;

    span {
      display: none;
    }
  }
`;

const Description = styled(Link)`
  flex-basis: 60%;
  display: inline-block;
  font-size: 14px;
  width: 100%;
  text-align: center;
  color: #444444;
  margin-bottom: 16px;
  font-family: "Open Sans", sans-serif;
  &:hover {
    color: ${PRIMARY};
  }

  @media (min-width: 767px) {
    text-align: left;
    font-size: 15px;
  }
`;

const DescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  grid-column: 1 / 5;
  gap: 5px;
  font-size: 12px;
  padding: 10px 0;

  @media (min-width: 767px) {
    flex-direction: row;
    grid-column: auto;
  }
`;

const Photo = styled(Link)`
  img {
    width: 100%;
    height: 150px;
    object-fit: contain;
    border: 1px solid #ddd;
    &:hover {
      border-color: ${PRIMARY};
    }
  }

  @media (min-width: 767px) {
    img {
      width: 150px;
      margin-right: 10px;
    }
  }
`;

const StyledInputArea = styled.div`
  display: flex;
  @media (min-width: 767px) {
    margin-top: 2px;
  }
`;

const StyledInput = styled.input`
  font-family: "Open Sans", sans-serif;
  font-size: 15px;
  height: 20px;
  width: 30px;
  padding: 2px;
  text-align: center;

  &:focus {
    outline: none;
  }
`;
