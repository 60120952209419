import React, { useState } from "react";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { addToBasket, getBasket } from "../../../axios/api";
import { PRIMARY } from "../../../vars/vars";
import styles from "./CatalogueItemCard.module.scss";
import { faCartPlus } from "@fortawesome/free-solid-svg-icons/faCartPlus";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useAppContext } from "../../../context/AppContext";
import NoPhoto from "./../../../assets/pedders-no-image.webp";
import { PulseLoader } from "react-spinners";

const findDefaultStockLocation = (available) => {
  return available.find((e) => e.default).location;
};

const CatalogueItemCard = ({
  part,
  part: {
    description,
    price,
    photos,
    id,
    name,
    available,
    urlcomponent,
    availabledetails,
  },
  parameter,
}) => {
  const {
    setBasketId,
    basketId,
    setBasketCount,
    setBasket,
    userCountry,
    setSummary,
  } = useAppContext();
  const [isLoading, setIsLoading] = useState(true);
  const [buttonText, setButtonText] = useState("Dodaj do koszyka");
  const history = useHistory();
  const defaultStockLocation = findDefaultStockLocation(availabledetails);

  const addHandler = (record) => {
    setButtonText("Dodawanie...");
    addToBasket(
      part,
      record,
      1,
      basketId,
      userCountry.code,
      defaultStockLocation
    )
      .then(({ data }) => {
        const { cart_id } = data;
        if (!basketId) {
          setBasketId(cart_id);
        }
        return cart_id;
      })
      .then((cart_id) => getBasket(cart_id))
      .then(({ data }) => {
        const {
          shoppingcarts: [{ shoppingcart }],
          summary,
        } = data;

        const quantity = shoppingcart.transactionlines
          .filter((obj) => obj.kitcomponent !== true)
          .reduce((prev, curr) => prev + Number(curr.quantity), 0);

        setBasketCount(quantity);
        setBasket(shoppingcart);
        setSummary(summary);
        setButtonText("Dodano");
        setTimeout(() => {
          setButtonText("Dodaj do koszyka");
        }, 1000);
      });
  };

  const sendCAPI = async () => {};

  const goToProductPage = () => {
    history.push("/details/" + urlcomponent);
  };

  const isAvailable = available > 0;

  return (
    <div className={`${styles.CatalogueItemCard} "gtm_productcard"`}>
      <StyledPictureLink to={"/details/" + urlcomponent}>
        <ImageWrapper>
          {isLoading && (
            <SkeletonLoader>
              <PulseLoader color="#999" size={10} />
            </SkeletonLoader>
          )}
          <picture>
            <source
              srcSet={photos && photos.length ? photos[0].url : NoPhoto}
              type="image/webp"
            />
            <StyledImage
              src={photos && photos.length ? photos[0].url : NoPhoto}
              alt={`${name} - ${description}`}
              title={`Zobacz szczegóły - ${name}`}
              loading="lazy"
              onLoad={() => setIsLoading(false)}
              style={{ opacity: isLoading ? 0 : 1 }}
            />
          </picture>
        </ImageWrapper>
      </StyledPictureLink>
      <StyledParameter>{parameter}</StyledParameter>
      <StyledNameLink to={"/details/" + urlcomponent}>{name}</StyledNameLink>
      <StyledLink to={"/details/" + urlcomponent}>{description}</StyledLink>
      <Price>
        {price === null ? "---" : price.grossprice}{" "}
        {price === null ? "zł" : price.symbol}
        {price.promotion && (
          <OldPrice>
            {price.baseprice} {price === null ? "zł" : price.symbol}
          </OldPrice>
        )}
      </Price>
      {price.promotion && (
        <OldPriceInfo>
          Najniższa cena z 30 dni przed obniżką: {price.baseprice}{" "}
          {price === null ? "zł" : price.symbol}
        </OldPriceInfo>
      )}
      {isAvailable ? (
        <Button
          isAvailable={isAvailable}
          onClick={() => addHandler(id)}
          className="gtm_btn_addtocart"
        >
          <FontAwesomeIcon
            size={"lg"}
            icon={faCartPlus}
            style={{ pointerEvents: "none" }}
          />
          <StyledSpan style={{ pointerEvents: "none" }}>
            {buttonText}
          </StyledSpan>
        </Button>
      ) : (
        <Button isAvailable={isAvailable}>
          <StyledSpan onClick={goToProductPage} style={{ marginLeft: "0" }}>
            Sprawdź dostępność
          </StyledSpan>
        </Button>
      )}
    </div>
  );
};

export default CatalogueItemCard;

const StyledParameter = styled.p`
  font-size: 13px;
  margin: 5px 10px;
  max-width: 230px;
  overflow-wrap: break-word;
  padding-bottom: 10px;
  text-align: center;
  font-family: "Open Sans", sans-serif;
  border-bottom: 1px solid #d0d0d0;
`;

const StyledNameLink = styled(Link)`
  color: ${PRIMARY};
  font-size: 18px;
  font-family: "Open Sans", sans-serif;

  &:hover {
    text-decoration: underline;
    color: ${PRIMARY};
  }
`;

const StyledLink = styled(Link)`
  color: #444444;
  font-family: "Open Sans", sans-serif;
  font-size: 14px;
  text-align: center;
  padding: 0 10px;
  font-weight: 600;

  &:hover {
    color: ${PRIMARY};
  }
`;

const Price = styled.div`
  font-family: "Open Sans", sans-serif;
  font-weight: 700;
  color: ${PRIMARY};
  font-size: 24px;
  margin: 5px 0 10px 0;
`;

const OldPrice = styled.span`
  color: black;
  text-decoration: line-through;
  font-size: 18px;
  margin-left: 8px;
`;

const StyledPictureLink = styled(Link)`
  img {
    width: 250px;
    height: 250px;
    object-fit: contain;
    padding: 10px;
  }
`;

const Button = styled.button`
  margin-bottom: 20px;
  color: white;
  background-color: ${(props) => (props.isAvailable ? "#50BE19" : "#ff9800")};
  text-transform: uppercase;
  font-family: "Open Sans", sans-serif;
  font-size: 12px;
  font-weight: 700;
  outline: none;
  border: none;
  padding: 10px 12px;
  min-width: 150px;

  &:hover {
    background-color: ${(props) => (props.isAvailable ? "#48ab16" : null)};
  }
`;

const StyledSpan = styled.span`
  margin-left: 10px;
`;

const OldPriceInfo = styled.span`
  color: #666666;
  font-size: 13px;
  margin: 0 10px 10px;
  text-align: center;
`;

const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const SkeletonLoader = styled.div`
  position: absolute;
  inset: 0;
  background: #f0f0f0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.3s ease;
`;

const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.3s ease;
  opacity: ${(props) => (props.isLoaded && !props.hasError ? 1 : 0)};
`;
