import React from "react";
import { Alert } from "react-bootstrap";
import styled from "styled-components";
import styles from "./PaymentStatus.module.scss";

const PaymentStatus = ({ orderName, closed }) => (
  <>
    {closed ? (
      <Alert className={styles.paymentStatus} variant={"dark"}>
        <StyledSpan>
          Twoje zamówienie zostało zamknięte - {orderName}
        </StyledSpan>
      </Alert>
    ) : (
      <Alert className={styles.paymentStatus} variant={"success"}>
        <StyledSpan>Twoje zamówienie zostało opłacone - {orderName}</StyledSpan>
      </Alert>
    )}
  </>
);

export default PaymentStatus;

const StyledSpan = styled.span`
  font-family: "Open Sans", sans-serif;
  display: inline-block;
  font-size: 18px;
  color: #0c5460;
`;
